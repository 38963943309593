<template>

<div class="address_edit">
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-form-item label="收货人" prop="name">
            <el-input v-model="form.name"  placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入联系手机号" ></el-input>
        </el-form-item>
        <el-form-item label="所在地区">           
            <span v-if="form.province" class="address_tip"  @click="onChoose">
                {{form.province}} {{form.city}} {{form.area}}
            </span>
            <span v-else class="address_tip"  @click="onChoose">
                请选择省市地区
                <i class="el-icon-arrow-down" ></i>
            </span>
            <div class="divwrap" v-if="show">
                <v-distpicker type="mobile" @province="onChangeProvince" @city="onChangeCity" @area="onChangeArea" ></v-distpicker>
            </div>
        </el-form-item>
        <el-form-item label="详细地址" prop="detail">
            <el-input v-model="form.detail" placeholder="请输入详细地址" ></el-input>
        </el-form-item>
        <el-form-item v-if="type == 'add'">
            <el-checkbox v-model="form.defaultFlag">设为默认收货地址</el-checkbox>
        </el-form-item>

    </el-form>
    <div class="right_align">
        <el-button class="warning" @click="onCancel">取 消</el-button>
        <el-button class="warning" type="warning" @click="onSure('form')">确 定</el-button>
    </div>
</div>

</template>

<script type="text/ecmascript-6">

import {addressUpdate, addressAdd} from "@/api/account.js";

import VDistpicker from 'v-distpicker'

export default {
    props:{
        addressInfo:{
            type:Object,
            default:{},
        },
        type:{
            type:String,
            default:"add",  
        }
    },
    data(){
        var validatePhone = (rule, value, callback) => {
            let  reg=/^[0-9]{11}$/
            if (!reg.test(value)) {
                callback(new Error('请输入正确11位手机号'));
            }else{
                callback();
            }
        };
        return {
            form:{},
            isActive:false,
            show:false,
            rules:{
                name: [
                            { required: true, message: '请输入收货人姓名', trigger: 'change' }
                        ],
                province: [
                            { required: true, message: '请选择省市区',trigger: 'blur' }
                        ],
                phone: [
                            { validator: validatePhone, trigger: 'blur' }
                        ],
                detail: [
                            { required: true, message: '请输入详细地址', trigger: 'change' }
                        ],

            }

        }
    },
    created(){
        this.form = this.addressInfo
    },
    methods:{
        onChoose(){
            this.show = !this.show
        },
        onChangeProvince(data){
            this.form.province = data.value
        },
        onChangeCity(data){
            this.form.city = data.value
        },
        onChangeArea(data){
            this.form.area = data.value
            this.show = false
        },
        onCancel(){
            this.$emit("close")
        },
        onSure(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(!this.form.province){
                        this.$modal.msgWarning("请选择省市区")
                        return
                    }
                    let params = {
                        name : this.form.name,
                        phone : this.form.phone,
                        province : this.form.province,
                        city : this.form.city,
                        area : this.form.area,
                        detail : this.form.detail,
                        defaultFlag : this.form.defaultFlag || false
                    }
                    if(this.type == "add"){
                        addressAdd(params).then(res => {
                            if(res.app_result_key == 0){
                                this.$modal.alertSuccess("地址添加成功")
                            }
                            this.$emit("close")
                        })
                    }else{
                        params.id = this.form.id
                        addressUpdate(params).then(res=>{
                            if(res.app_result_key == 0){
                                this.$modal.alertSuccess("地址修改成功")
                            }
                            this.$emit("close")
                        })

                    }
                    
                } else {
                    return false;
                }

            })
        },
    },
    components: { 
        VDistpicker
     }

}
</script>

<style>
.divwrap .distpicker-address-wrapper .address-container ul li{width: 120px; float: left; box-sizing: border-box;cursor: pointer;}
.divwrap .distpicker-address-wrapper .address-container ul li.active{color:#f06400;}
.divwrap .distpicker-address-wrapper .address-header ul li{color:#f06400;}
.divwrap .distpicker-address-wrapper .address-container ul{max-height: 300px;}
.address_edit .address_tip{color:#f06400; cursor: pointer;}
</style>
