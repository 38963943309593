<template>

<div class="consumer">
    <div class="tip">
        <i class="el-icon-warning"></i>	{{textTip}}
    </div>
    <div class="consumer_body">
        <el-table ref="table" :data="tableData" stripe @select-all="handleSelectAll" @select="selectARow">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="交易时间" prop="createTime" width="150px" show-overflow-tooltip></el-table-column>
            <el-table-column label="项目" prop="orderNumber" width="200px" show-overflow-tooltip></el-table-column>
            <el-table-column label="金额" prop="money" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
        </el-table>
        <el-pagination v-if="total/pageSize>1" :current-page.sync="pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
                background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
    </div>
    <div class="consumer_total">
        已选择 <span class="opera_active">{{multipleSelection.length}}</span> 条项目， 金额 <span class="opera_active">{{totalMoney}}</span> 元
    </div>
    <div class="right_align">
        <el-button class="warning" type="warning" @click="sure">确认</el-button>
        <el-button class="warning" @click="cancle">取消</el-button>
        <p style="margin-top:10px;">*订单开票后，您将不能申请该笔订单的退款</p>
        <p>*超过1年以上的开票金额请联系客服</p>
    </div>
</div>

</template>

<script>
import {selfRechargeList} from "@/api/finance.js"
import store from '@/store'
import { mapGetters } from "vuex";
export default {
    props:['rechargeType'],
    data(){
        return {
            tableData:[],
            pageNo:1,
            pageSize:5,
            total:0,
            tableLoading:false,
            multipleSelection: [],
            totalMoney:0,
            agentId : store.getters.agentId,
            orderInfo: undefined,
            textTip:"",
        }
    },
    mounted(){
        this._selfRechargeList()
        if(this.isCaptal=="1"){
         this.textTip = "如果您已打款至账户，财务审核后就会充值到账"
        }else{
            this.textTip = "如果您已打款至诺行账户，财务审核后就会充值到账"
        }
    },
    methods:{
        sure(){
            if(this.multipleSelection.length==0){
                this.$modal.msgWarning("请至少选择一条订单")
                return
            }
            this.$emit("orderInfo", this.orderInfo)
        },
        cancle(){
            this.$emit("close")
        },
        selectARow(val,row){
            let selectBool = val.length && val.indexOf(row) !== -1   //当前点击是否勾选
            if(!selectBool){  //取消勾选
                this.multipleSelection.forEach((item,i) => {
                    if(item.id == row.id){
                        this.multipleSelection.splice(i,1)
                    }
                })

            }else{ //选中
                this.multipleSelection.push(row)
            }
            this.calculation()
        },
        handleSelectAll(val){
            if(val.length == this.tableData.length){ //全选
                this.multipleSelection = this.multipleSelection.concat(val)
                //去重
                let obj = {}
                let result = []
                this.multipleSelection.forEach(item => {
                    if(!obj[item.id]){
                        result.push(item)
                        obj[item.id] = true
                    }
                })
                this.multipleSelection = result

            }else{ //取消全选
                this.tableData.forEach(item => {
                   this.multipleSelection.forEach((multItem,i) => {
                       if(item.id == multItem.id){
                           this.multipleSelection.splice(i,1)
                       }
                   }) 
                })
            }
            this.calculation()
        },
        calculation(){
            let money = 0
            let orderNumber = []
            let createTime = []
            this.multipleSelection.forEach(item=>{
                money = money + item.money
                orderNumber.push(item.orderNumber)
                createTime.push(item.createTime)
            })
            this.totalMoney = money
            this.orderInfo = {
                money,
                orderNumber: orderNumber.join(","),
                createTime:createTime.join(",")
            }
        },
        onPageChange(page){
			this.page = page
			this._selfRechargeList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._selfRechargeList()
        },
        _selfRechargeList(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                agentId:this.agentId,
                status:1,
                rechargeType:this.rechargeType
            }
            this.tableLoading = false
            selfRechargeList(params).then(res =>{
                this.tableLoading = true
                this.tableData = res.rows
                this.total = res.total
                this.$nextTick(()=>{
                    this.tableData.forEach(item => {
                        this.multipleSelection.forEach(multItem =>{
                            if(multItem.id == item.id){
                                console.log(item)
                                this.$refs.table.toggleRowSelection(item, true);
                            }
                            
                        })
                    })
                     
                })
            })
        }
    },
    computed: {
    ...mapGetters(["isCaptal"]),
  },

}
</script>

<style>
.consumer .tip{line-height: 34px; padding:0 16px; color:#fff; background: #f06400; border-radius: 3px; margin-bottom:6px;}
.consumer .tip i{ font-size: 20px; position: relative;top:2px; margin-right:5px;}
.consumer_total{padding: 40px 20px; margin:20px 0; border-top:1px solid #ebebeb; border-bottom:1px solid #ebebeb;}
</style>
