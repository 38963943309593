import request from './request.js'
export function enterpriseList(params) {
    return request({
		url:"statistics/adminstatis/ListMonthMealFlow.do?",
        method: 'post',
        data: params
    })
}
export function enterpriseSave(params) {
    return request({
        url: 'system/adminUser/enterpriseSave.do?',
        method: 'post',
        data: params
    })
}
export function updateEnterprisePsd(params) {
    return request({
        url: 'system/adminUser/upenterprisePsd.do?',
        method: 'post',
        data: params
    })
}

export function delEnterpriseById(params) {
    return request({
        url: 'system/adminUser/delEnterpriseById.do?',
        method: 'post',
        data: params
    })
}
export function addCard(params) {
    return request({
        url: 'enterpriseCard/addCard.do?',
        method: 'post',
        data: params,
		timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
export function delCard(params) {
    return request({
        url: 'enterpriseCard/delCard.do?',
        method: 'post',
        data: params,
		timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function getAgentList(params) {
    return request({
       url:"agent/list4agent.do?",
        method: 'post',
        data: params
    })
}

// 收货地址
export function addressList(params) {
    return request({
       url:"/buy/address/list.do?",
        method: 'post',
        data: params
    })
}
export function addressDelect(params) {
    return request({
       url:"/buy/address/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function addressUpdate(params) {
    return request({
       url:"/buy/address/update.do?",
        method: 'post',
        data: params
    })
}
export function addressAdd(params) {
    return request({
       url:"/buy/address/add.do?",
        method: 'post',
        data: params
    })
}
export function setAddressDefault(params) {
    return request({
       url:"/buy/address/setDefault.do?",
        method: 'post',
        data: params
    })
}