<template>

<div class="qualification">
    <el-table ref="table" :data="tableData" stripe>
        <el-table-column label="申请时间" prop="createTime" width="200px" show-overflow-tooltip></el-table-column>
        <el-table-column label="发票金额" prop="money" width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column label="发票类型" prop="billType" show-overflow-tooltip>
            <template slot-scope="scope">
                <span v-if="scope.row.billType ==0">普通发票</span>
                <span v-if="scope.row.billType ==1">增值发票</span>
            </template>
        </el-table-column>
        <el-table-column label="发票信息" prop="billInfo" show-overflow-tooltip></el-table-column>
        <el-table-column label="状态" prop="status" show-overflow-tooltip>
            <template slot-scope="scope">
                <span v-if="scope.row.status == 0">待审核</span>
                <span v-if="scope.row.status == 1">审核通过</span>
                <span v-if="scope.row.status == 2">审核失败</span>
            </template>
        </el-table-column>
         <el-table-column label="未开票原因" prop="reason" show-overflow-tooltip></el-table-column>
          <el-table-column label="发票下载地址" prop="loadUrl" show-overflow-tooltip></el-table-column>
        <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
                <el-button v-if="scope.row.status != 1" size="mini" class="themed-button" type="danger" @click="onDelete(scope.row.id)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination v-if="total/pageSize>1" :current-page.sync="pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
</div>

</template>

<script>
import {getBillApplyList, billApplyDelete} from "@/api/finance.js"
import store from '@/store'
export default {
    data(){
        return {
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            tableLoading:false,
            agentId:store.getters.currentAgentId,
        }
    },
    mounted(){
        this._getBillApplyList()
    },
    methods:{

        onDelete(id){
            id = parseInt(id)
            this.$modal.confirm("确定要删除该条记录吗?").then(res=>{
                billApplyDelete({id}).then(res=>{
                    this.$modal.alertSuccess("删除成功")
                    this._getBillApplyList()
                })
            })

        },
        onPageChange(page){
			this.page = page
			// this._getMonthDayFlow()
		},
		handleSizeChange(size){
			this.pageSize = size
			// this._getMonthDayFlow()
        },
        _getBillApplyList(){
            this.tableLoading = true
            getBillApplyList({agentId:this.agentId}).then(res =>{
                this.tableLoading = false
                this.tableData = res.rows
            })
        }
    }
}
</script>

<style>
</style>
