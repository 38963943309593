<template>

<div class="invoice">
    <div class="invoice_tip">
        <p>关于发票</p>
        <p>1、支持普通发票</p>
        <p>2、单次开票金额须大于200元，发票包邮；</p>
        <p>3、每月25号之前，发票申请提交后3-5个工作日寄出；</p>
        <p>4、每月25-30日为公司内部结算日，不再开票；申请的发票次月初寄出，请合理安排发票申请时间。</p>
        <p style="color:#f00">5、充值余额使用微信支付开票只能开普通发票</p>
    </div>
    <div class="invoice_sel">
        <el-radio-group v-model="invoiceType" @change="clearValidate('form')">
            <el-radio-button :label="1">普通发票</el-radio-button>
            <!-- <el-radio-button :label="2">增值税专用发票</el-radio-button> -->
        </el-radio-group>
    </div>

    <el-form class="invoice_form" ref="form" :model="form" label-width="120px" :rules="rules" label-position="left">
        <el-form-item label="增票资质" v-if="invoiceType == 2">
            <div class="qualifications" v-if="isPass">
                <p>增票资质信息核对</p>
                <div>公司名称：{{qualifications.bussinessName}}</div>
                <div>纳税人识别码：{{qualifications.taxNo}}</div>
                <div>注册地址：{{qualifications.address}}</div>
                <div>注册电话：{{qualifications.regPhone}}</div>
                <div>开户银行：{{qualifications.bank}}</div>
                <div>开户名：{{qualifications.bussinessName}}</div>
                <div>银行账号：{{qualifications.bankCode}}</div>
            </div>
            <div class="qualifications" v-else>
                <div class="nopass">首次申请开具增值税专用发票，请在【 <span class="opera_active" @click="toQuality">增票资质</span> 】页面上传资料，再申请发票</div>
            </div>
        </el-form-item>
        <el-form-item label="发票金额" prop="money">
            <div class="moneybox">
                <el-input v-model="form.money" style="opacity:0"></el-input>
                <div class="money">
                    <span>{{form.money}}</span>元
                    <span class="opera_active" @click="selMoney">选择开票金额</span>
                </div>
            </div>
        </el-form-item>
        <el-form-item label="发票抬头" prop="bussinessName" v-if="invoiceType == 1">
            <el-input v-model="form.bussinessName"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号" prop="taxNo" v-if="invoiceType == 1">
            <el-input v-model="form.taxNo" placeholder="企业纳税人识别号或统一社会信用代码" maxlength="64"></el-input>
        </el-form-item>
        <el-form-item label="发票内容">
            <el-button  class="warning" type="warning" plain >技术服务费</el-button>
        </el-form-item>
        <el-form-item label="邮箱" prop="mail" v-if="invoiceType == 1">
            <el-input v-model="form.mail" placeholder="邮箱" maxlength="64"></el-input>
        </el-form-item>
        <el-form-item label="收货人信息" >
            <el-select class="address" v-model="form.address">
                <el-option v-for="(item, index) in AddressList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-button style="margin-left:10px;" class="warning" @click="addAddress">新增收货地址</el-button>
        </el-form-item>
        <el-form-item>
            <el-button  class="warning" type="warning" @click="apply('form')">申请发票</el-button>
        </el-form-item>
    </el-form>

    <el-dialog title="选择消费项目" :visible.sync="dialogConsumerVisible" v-if="dialogConsumerVisible">
        <consumer-items :rechargeType="rechargeType" @orderInfo="orderInfo" @close="consumerClose" ></consumer-items>
    </el-dialog>

    <el-dialog title="新增地址" :visible.sync="dialogVisible">
        <address-edit :addressInfo="defaultList" @close="closeAddress" v-if="dialogVisible"></address-edit>
    </el-dialog>
</div>


</template>

<script>

import {billApply, increBillApply, increBillList,increBillForAgent,addressList} from "@/api/finance.js"


import consumerItems from "./consumerItems.vue"
import AddressEdit from "@/components/addressEdit.vue"

import store from '@/store'
export default {
    data(){
        var validateMoney = (rule, value, callback) => {
            // let  reg=/^\d+(\.\d{0,2})?$/
            if(!value){
                callback(new Error('单次开票金额须大于200元'));
            }
            if(value < 200){
                callback(new Error('单次开票金额须大于200元'));
            }else{
                callback();
            }
        };
        var validateNumber1 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('纳税人识别号不能为空'));
            } else {
                let  reg=/^[0-9a-zA-Z]*$/
                if (!reg.test(value)) {
                    callback(new Error('纳税人识别号只能包含数字和字母'));
                }else{
                    callback();
                }
                
            }
        };
        return {
            invoiceType:"1",  //1:普通, 2:增值
            rechargeType:'',  //开票订单是全部,还是银行卡
            form:{
                money:0,
                orderNumber: '',
                bussinessName:'',
                taxNo:'',
                mail:'',
                address:'',
                orderTime:'',
                billContent:"技术服务费",
                agentId:store.getters.currentAgentId,
            },
            isPass:false,
            qualifications:{},
            rules:{ 
                money:[
                    { validator: validateMoney, trigger: 'blur' }
                ],
                bussinessName:[
                    { required: true, message: '发票抬头不能为空', trigger: 'blur' }
                ],
                taxNo:[
                    { validator: validateNumber1, trigger: 'blur' }
                ],
                address:[
                    { required: true, message: '请选择收货地址', trigger: 'blur' }
                ],
                mail:[
                   { required: true, message: '邮箱不能为空', trigger: 'blur' },
                   { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
               ] ,

            },
            dialogConsumerVisible:false,
            AddressList:[],
            dialogVisible:false,
            defaultList : {
                        name : "",
                        phone : "",
                        province : "",
                        city :"",
                        area : "",
                        detail : "",
                        defaultFlag : false,
                    }
        }
    },
    created(){
        this._addressList()
        this._increBillForAgent()
        console.log(this.form.agentId)
    },
    methods:{
        clearValidate(formName){
            this.$refs[formName].clearValidate();
        },
        orderInfo(info){
            this.form.money = info.money
            this.form.orderNumber = info.orderNumber
            this.form.orderTime = info.createTime
            this.dialogConsumerVisible = false
        },
        consumerClose(){
            this.dialogConsumerVisible = false
        },
        apply(formName){
            if(this.invoiceType == "2"){ //增值发票
                if(!this.isPass){
                    this.$modal.msgWarning("请先上传增票资质,并等待审核通过")
                    return
                }
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.invoiceType == "1"){
                        billApply(this.form).then(res =>{
                            this.$modal.alertSuccess("普通发票申请成功")
                        })
                    }else{
                        let params = {}
                        params.bussinessName = this.qualifications.bussinessName
                        params.taxNo = this.qualifications.taxNo
                        params.address = this.qualifications.address 
                        params.regPhone = this.qualifications.regPhone 
                        params.bank = this.qualifications.bank 
                        params.bankCode = this.qualifications.bankCode
                        Object.assign(params, this.form)
                        params.taxNo = this.qualifications.taxNo
                        increBillApply(params).then(res =>{
                            this.$modal.alertSuccess("增值发票申请成功")
                        })
                    }
                    
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        addAddress(){
            this.dialogVisible = true
        },
        closeAddress(){
            this.dialogVisible = false
            this._addressList()
        },
        toQuality(){
            this.$emit("toQuality")
        },
        selMoney(){
            if(this.invoiceType == "2"){
                this.rechargeType = 3
            }else{
                this.rechargeType = ''
            }
            this.dialogConsumerVisible = true
        },
        _addressList(){
            this.tableLoading = true
            addressList().then((data)=>{
                if(data.length){
                    this.AddressList = data.map(item =>{
                        let address = item.province + item.city + item.area + item.detail
                        return {
                            label:address,
                            value:address
                        }
                    })
                }
                
            })
        },
        _increBillForAgent(){
            increBillForAgent({agentId:this.form.agentId}).then((res)=>{
                console.log(res)
                this.isPass = res==""?false:true
                if(res){
                    let data = res
                    this.isPass = data.status==1?true:false
                    this.qualifications.bussinessName = data.bussinessName
                    this.qualifications.taxNo = data.taxNo
                    this.qualifications.address = data.address
                    this.qualifications.regPhone = data.regPhone
                    this.qualifications.bank = data.bank
                    this.qualifications.bankCode = data.bankCode
                }
            })
        },
    },
    components:{
        consumerItems,
        AddressEdit
    }
}
</script>

<style>
.el-select-dropdown__list{max-width:550px}
.invoice{padding:20px; background: #fff;}
.invoice_form{max-width: 700px;}
.invoice_tip{ padding-bottom:20px; border-bottom:1px solid #ebebeb; line-height: 20px;}
.invoice_sel{margin:20px 0;}
.invoice .read_only .el-input__inner{border:0}
.opera_active{cursor: pointer; color:#f06400;}
.invoice .qualifications{ background-color: #f5f8fa; border: 1px solid #ccc; padding: 10px; line-height: 24px; border-radius: 5px;}
.invoice .qualifications .nopass{padding:90px;}
.invoice .address .el-input--small .el-input__inner{height:35px;}
.invoice .moneybox{position: relative;}
.invoice .moneybox .money{position: absolute; top:0; left:0}
.el-radio-button__orig-radio:checked+.el-radio-button__inner{background-color: #f06400;border-color: #f06400;box-shadow: -1px 0 0 0 #f06400;}
.el-radio-button__inner:hover{color:#f06400;}
</style>
