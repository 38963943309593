<template>
    <div class="remark-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">收货地址</span>
            <div class="hook right">
                <el-button class="themed-button" type="primary" @click="addAderess">新增地址</el-button>
            </div>
            
        </div>

        <div class="content-box" v-loading='tableLoading'>
            <el-table ref="table" :data="tableData" stripe :max-height="maxTableHeight">
                <el-table-column label="收货人" prop="name" width="200px" show-overflow-tooltip></el-table-column>
                <el-table-column label="手机号" prop="phone" width="150px" show-overflow-tooltip></el-table-column>
                <el-table-column label="所在地区" show-overflow-tooltip>
                    <template slot-scope="scope">
						<span>{{scope.row.province}} {{scope.row.city}} {{scope.row.area}} {{scope.row.detail}}</span>
					</template>
                </el-table-column>
                <el-table-column label="操作" width="240px">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.defaultFlag" disabled>默认地址</el-button>
						<el-button v-else class="themed-button" type="primary" @click="setDefault(scope.row)">设为默认地址</el-button>
						<el-button class="themed-button" type="primary" @click="editAddress(scope.row)">编辑</el-button>
						<el-button class="themed-button" type="danger" @click="delect(scope.row)">删除</el-button>
					</template>
                </el-table-column>
            </el-table>
            <!-- <el-pagination :current-page.sync="pageNo"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination> -->
        </div>


        <el-dialog :title="title" :visible.sync="dialogVisible">
            <address-edit :type="type" :addressInfo="addressInfo" @close="close" v-if="dialogVisible"></address-edit>
        </el-dialog>

    </div>
</template>

<script>

import resize from '@/mixins/resize.js'
import {addressList, addressDelect, addressUpdate, setAddressDefault} from "@/api/account.js";

import AddressEdit from "@/views/agentManagement/InvoiceRequest/addressEdit.vue"
export default {
    name:'Address',
    mixins:[resize],
    data(){
        return {
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            tableLoading:false,
            title:"",
            dialogVisible:false,
            addressInfo:{},
            type:""
        }
    },
    mounted(){
        this._addressList()
    },
    methods: {
        editAddress(row){
            this.title = "编辑地址"
            this.type = "edit"
            this.addressInfo = row
            this.dialogVisible = true  
        },
        setDefault(row){
            setAddressDefault({id:row.id}).then(res=>{
                this.$modal.alertSuccess("设置成功");
                this._addressList()
            })
        },
        delect(row){
            this.$modal.confirm("确定删除这条地址吗？").then(res => {
                this.tableLoading = true
                addressDelect({id:row.id}).then(res =>{
                    this.tableLoading = false
                    this.$modal.alertSuccess("删除成功")
                    this._addressList()
                })
            })
        },
        addAderess(){
            this.title = "新增地址"
            this.type = "add"
            this.addressInfo = {}
            this.dialogVisible = true
        },
        close(){
            this.dialogVisible = false
            this._addressList()
        },
        onPageChange(page){
			this.page = page
			this._addressList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._addressList()
        },
        _addressList(){
            this.tableLoading = true
            addressList().then((res)=>{
                this.tableLoading = false
                // this.pageNo = res.pageNo
                // this.pageSize = res.pageSize
                // this.total = res.total
                this.tableData = res
            })
        },
    },
    components:{
        AddressEdit
    }
}
</script>
<style>
.iccid-width .el-input__inner{width:180px !important;}
</style>