/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './request.js'

//发票申请页面
export function getBillApplyList(params) {  //获取发票记录
    return request({
        url: 'billApply/list.do?',
        method: 'post',
        data: params
    })
}
export function billApply(params) {  //普通发票申请
    return request({
        url: 'billApply/billApply.do?',
        method: 'post',
        data: params
    })
}
export function increBillApply(params) {  //增值发票申请
    return request({
        url: 'billApply/increBillApply.do?',
        method: 'post',
        data: params
    })
}
export function increBillList(params) {  //增值发票获取
    return request({
        url: 'billApply/increBillList.do?',
        method: 'post',
        data: params
    })
}
export function increBillCheck(params) {   //申请增值发票状态
    return request({
        url: 'billApply/increBillCheck.do?',
        method: 'post',
        data: params
    })
}
export function increBillForAgent(params) {   //获取增票资质信息
    return request({
        url: 'billApply/increBillForAgent.do?',
        method: 'post',
        data: params
    })
}
export function increBillSubmit(params) {   //提交增票资质信息
    return request({
        url: 'billApply/increBillSubmit.do?',
        method: 'post',
        data: params
    })
}
export function selfRechargeList(params) {   //获取发票订单
    return request({
        url: 'selfRecharge/list.do?',
        method: 'post',
        data: params
    })
}

//获取微信支付路径 
export function getPayURL(params) {
    return request({
        url: "/pay/getPayUrl.do?",
        method: 'post',
        data: params
    })
}
export function alreadyPay(params) {
    return request({
        url: "/pay/alreadyPay.do?",
        method: 'post',
        data: params
    })
}
export function selfRechargeSubmit(params) {
    return request({
        url: "selfRecharge/submit.do?",
        method: 'post',
        data: params
    })
}
export function billApplyDelete(params) {
    return request({
        url: "billApply/delete.do?",
        method: 'post',
        data: params
    })
}
export function commonUploadFile(params) {   // 上传图片
    return request({
        url: "commonUploadFile/upload.do?",
        method: 'post',
        data: params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function _commonUploadFile(file,url){
    if(file == ""){
        return new Promise((resove, reject)=>{
            resove({url})
        })
    }else{
        let formData = new FormData()
        formData.append("file", file)
        
        return new Promise((resove, reject)=>{
            commonUploadFile(formData).then(res=>{
                resove(res)
            })
        })
    }
    
}


export function exportTobExcelSync(params) {
    return request({
       url:"agent/agentrechargelog/exportTobExcelSync.do?",
        method: 'post',
        data: params
    })
}

export function updateWarnMoney(params) {
    return request({
       url:"/agent/agentrecharge/updateWarnMoney.do?",
        method: 'post',
        data: params
    })
}

// 收货地址
export function addressList(params) {
    return request({
       url:"/buy/address/list.do?",
        method: 'post',
        data: params
    })
}

export function addressUpdate(params) {
    return request({
       url:"/buy/address/update.do?",
        method: 'post',
        data: params
    })
}
export function addressAdd(params) {
    return request({
       url:"/buy/address/add.do?",
        method: 'post',
        data: params
    })
}