<template>
    <div class="common Invoice_Request fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">发票申请</span>
        </div>

        <div class="tabBox">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="申请发票" name="1">
                    <invoice @toQuality="toQuality"  v-if="activeName == 1"></invoice>
                </el-tab-pane>
                <el-tab-pane label="发票记录" name="2">
                    <invoice-record v-if="activeName == 2"></invoice-record>
                </el-tab-pane>
                 <el-tab-pane label="地址管理" name="3">
                    <address-list v-if="activeName == 3"></address-list>
                </el-tab-pane>
                <!-- <el-tab-pane label="增票资质" name="3">
                    <qualification v-if="activeName == 3" @toInvoice="toInvoice"></qualification>
                </el-tab-pane> -->
            </el-tabs>
        </div>

    </div>
</template>

<script>

import Invoice from "./InvoiceRequest/invoice.vue"
import InvoiceRecord from "./InvoiceRequest/invoiceRecord.vue"
import addressList from "./InvoiceRequest/address.vue"
// import Qualification from "./InvoiceRequest/qualification.vue"

export default {
    name:'InvoiceRequest',
    data(){
        return {
            activeName:'1',
        }
    },
    mounted(){
        
    },
    methods: {
        toInvoice(){
            this.activeName = '1'
        },
        toQuality(){
            this.activeName = '3'
        },
        handleClick(){

        },
    },
    components:{
        Invoice,
        InvoiceRecord,
        addressList,
        // Qualification
    }
}

</script>

<style>
.Invoice_Request .tabBox .el-tabs__header{background: #fff;;}
.Invoice_Request .el-tabs__item{font-size: 16px;width:150px;text-align: center; padding:0; color: #8391a5;}
.Invoice_Request .el-tabs__active-bar{height:3px}
.Invoice_Request .el-tabs__nav-wrap::after{height:0;}
.Invoice_Request .el-tabs__item{height:60px; padding-top:15px;}
</style>